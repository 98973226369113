import React from 'react';
import {
  object, func,
  string, node,
} from 'prop-types';

import loadable from '@loadable/component';

const Typography = loadable(() => import('@material-ui/core/Typography'));
const Card = loadable(() => import('@material-ui/core/Card'));
const CardActions = loadable(() => import('@material-ui/core/CardActions'));
const CardContent = loadable(() => import('@material-ui/core/CardContent'));
const Button = loadable(() => import('@material-ui/core/Button'));

const BaseCard = ({
  classes,
  onCardClick = () => {},
  title,
  imageChild = null,
  description = '',
  buttonMessage,
  iconChild,
}) => (
      <Card onClick={onCardClick} className={classes.card}>
        {imageChild}
        <CardContent className={classes.descriptionContainer}>
          <Typography
            gutterBottom
            variant="h5"
            className={classes.title}
            component="h2">
            {title}
          </Typography>
          <Typography component="p" className={classes.description}>
            {description}
          </Typography>
          <CardActions>
            <Button
            aria-label={title}
            fullWidth={true}
            className={classes.cardbutton}
            size="large"
            variant="contained"
            color="default"
            onClick={onCardClick}>
              {buttonMessage}
              {iconChild}
            </Button>
          </CardActions>
        </CardContent>
      </Card>
);

BaseCard.propTypes = {
  imageChild: node.isRequired,
  classes: object.isRequired,
  onCardClick: func,
  title: string.isRequired,
  description: string.isRequired,
  buttonMessage: string,
  iconChild: node,
};

export default BaseCard;
